import React, { Component } from "react";
import {
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormTextarea,
  Button,
  Container,
  CardHeader,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormCheckbox
} from "shards-react";
import PageTitle from "./../../components/common/PageTitle";
// import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { strings } from "./../../localization/Inquire";
import { API_URL, Language, updateNumber } from "./../../config";
import { Link } from "react-router-dom";
// import Avatar from "react-avatar-edit";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

export default class EditUserProfile extends Component {
  constructor(props) {
    super(props);
    strings.setLanguage(Language);

    this.state = {
      org_code: "",
      name: "",
      rutNumber: "",
      city: "",
      state: "",
      giro: "",
      giroCodes: "",
      loader: "",
      src: "",
      id: "",
      address: "",
      siiAddress: "",
      indServicio: "",
      boletaFchResol: new Date(),
      boletaNroResol: "",
      fchResol: new Date(),
      nroResol: "",
      rutReceptorSII: "",
      rutEnvia: "",
      dirOrigen: "",
      cmnaOrigen: "",
      billSequenceNumber: 0,
      invoiceSequenceNumber: 0,
      creditNoteSequenceNumber: 0,
      debitNoteSequenceNumber: 0,
      deliveryNoteSequenceNumber: 0,
      billWithoutTaxSequenceNumber: 0,
      invoiceWithoutTaxSequenceNumber: 0,
      quoteSequenceNumber: 0,
      button: false,
      status: true,
      submit_bill_sii: true,
      submit_invoice_sii: true,
      submit_dispatchnote_sii: true,
      restaurantModule: true,
      chooseLogoImage: '',
      logoErrorMessage: '',
    };
  }

  componentDidMount() {
    // let data = this.props.match.params;

    // let id = this.props.match.params.id;
    // alert(data.id);

    if (this.props.match.params.id) {
      fetch(`${API_URL}organisations/${this.props.match.params.id}`, {
        method: "GET",
        credentials: "include"
      })
        .then(res => res.json())
        .then(({ data }) => {
          console.log(
            "=================data i manage organization",
            data.submit_bill_sii,
            data.submit_invoice_sii,
            data.submit_dispatchnote_sii
          );
          // alert(data.boletaFchResol);
          let billWithoutTaxData = data.billWithoutTaxSequenceNumber === undefined ? 0 : data.billWithoutTaxSequenceNumber
          let invoiceWithoutTaxData = data.invoiceWithoutTaxSequenceNumber === undefined ? 0 : data.invoiceWithoutTaxSequenceNumber
          let quoteData = data.quoteSequenceNumber === undefined ? 0 : data.quoteSequenceNumber

          this.boletaFchResolRef.setStartDate(data.boletaFchResol);
          this.fchResolRef.setStartDate(data.fchResol);
          this.setState({
            org_code: data.organisationCode,
            name: data.name,
            rutNumber: data.rutNumber,
            city: data.city,
            state: data.state,
            giro: data.giro,
            giroCodes: data.giroCodes,
            address: data.address,
            siiAddress: data.siiAddress,
            indServicio: data.indServicio,
            boletaFchResol: data.boletaFchResol,
            boletaNroResol: data.boletaNroResol,
            fchResol: data.fchResol,
            nroResol: data.nroResol,
            rutReceptorSII: data.rutReceptorSII,
            rutEnvia: data.rutEnvia,
            status: data.status,
            dirOrigen: data.dirOrigen,
            cmnaOrigen: data.cmnaOrigen,
            billSequenceNumber: data.billSequenceNumber,
            invoiceSequenceNumber: data.invoiceSequenceNumber,
            creditNoteSequenceNumber: data.creditNoteSequenceNumber,
            debitNoteSequenceNumber: data.debitNoteSequenceNumber,
            deliveryNoteSequenceNumber: data.deliveryNoteSequenceNumber,
            billWithoutTaxSequenceNumber: billWithoutTaxData,
            invoiceWithoutTaxSequenceNumber: invoiceWithoutTaxData,
            quoteSequenceNumber: quoteData,
            chooseLogoImage: data.companyLogo === undefined ? '' : data.companyLogo,
            id: "/" + this.props.match.params.id,
            submit_bill_sii:
              data.submit_bill_sii === undefined ? false : data.submit_bill_sii,
            submit_invoice_sii:
              data.submit_invoice_sii === undefined
                ? false
                : data.submit_invoice_sii,
            submit_dispatchnote_sii:
              data.submit_dispatchnote_sii === undefined
                ? false
                : data.submit_dispatchnote_sii,
            restaurantModule: data.restaurantModule === undefined ? false : data.restaurantModule
          });
        });
    } else {
      this.setState({
        boletaFchResol: new Date(),
        fchResol: new Date()
      });
    }
  }

  convertDate = str => {
    var date = new Date(str);
    var mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);
    var hours = ("0" + date.getHours()).slice(-2);
    var minutes = ("0" + date.getMinutes()).slice(-2);
    return (
      date.getFullYear() +
      "-" +
      mnth +
      "-" +
      day +
      "T" +
      hours +
      ":" +
      minutes +
      ":00"
    );
  };

  render() {
    const {
      name,
      org_code,
      rutNumber,
      city,
      state,
      giro,
      giroCodes,
      address,
      siiAddress,
      indServicio,
      boletaFchResol,
      boletaNroResol,
      fchResol,
      nroResol,
      rutReceptorSII,
      rutEnvia,
      status,
      dirOrigen,
      cmnaOrigen,
      billSequenceNumber,
      invoiceSequenceNumber,
      creditNoteSequenceNumber,
      debitNoteSequenceNumber,
      deliveryNoteSequenceNumber,
      billWithoutTaxSequenceNumber,
      invoiceWithoutTaxSequenceNumber,
      quoteSequenceNumber,
      submit_bill_sii,
      submit_invoice_sii,
      submit_dispatchnote_sii,
      chooseLogoImage,
      restaurantModule: restaurantModule
    } = this.state;
    const handleClick = () => {
      if (
        name === "" ||
        org_code === "" ||
        rutNumber === "" ||
        city === "" ||
        state === "" ||
        giroCodes === "" ||
        address === "" ||
        siiAddress === "" ||
        indServicio === "" ||
        boletaFchResol === "" ||
        boletaNroResol === "" ||
        fchResol === "" ||
        nroResol === "" ||
        rutReceptorSII === "" ||
        rutEnvia === "" ||
        dirOrigen === "" ||
        cmnaOrigen === "" ||
        billSequenceNumber === "" ||
        invoiceSequenceNumber === "" ||
        creditNoteSequenceNumber === "" ||
        debitNoteSequenceNumber === "" ||
        deliveryNoteSequenceNumber === "" ||
        billWithoutTaxSequenceNumber === "" ||
        invoiceWithoutTaxSequenceNumber === "" ||
        quoteSequenceNumber === "" ||
        giro === ""
        // || chooseLogoImage === ""
      ) {
        toast.error("Fill all fields !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      } else {
        this.setState({ loader: "fa fa-spinner fa-spin", button: true });
        // let user_info = localStorage.getItem("login_info");
        // user_info = JSON.parse(user_info);
        // var user_id = user_info.id;
        let strings = JSON.stringify({
          name: name,
          organisationCode: org_code,
          rutNumber: rutNumber,
          city: city,
          state: state,
          giroCodes: giroCodes,
          siiAddress: siiAddress,
          address: address,
          giro: giro,
          status: status,
          indServicio: indServicio,
          boletaFchResol: boletaFchResol,
          boletaNroResol: boletaNroResol,
          fchResol: fchResol,
          nroResol: nroResol,
          rutReceptorSII: rutReceptorSII,
          rutEnvia: rutEnvia,
          dirOrigen: dirOrigen,
          billSequenceNumber: parseInt(billSequenceNumber),
          invoiceSequenceNumber: parseInt(invoiceSequenceNumber),
          creditNoteSequenceNumber: parseInt(creditNoteSequenceNumber),
          debitNoteSequenceNumber: parseInt(debitNoteSequenceNumber),
          deliveryNoteSequenceNumber: parseInt(deliveryNoteSequenceNumber),
          billWithoutTaxSequenceNumber: parseInt(billWithoutTaxSequenceNumber),
          invoiceWithoutTaxSequenceNumber: parseInt(invoiceWithoutTaxSequenceNumber),
          quoteSequenceNumber: parseInt(quoteSequenceNumber),
          cmnaOrigen: cmnaOrigen,
          submit_bill_sii: submit_bill_sii,
          submit_invoice_sii: submit_invoice_sii,
          submit_dispatchnote_sii: submit_dispatchnote_sii,
          restaurantModule: restaurantModule,
          companyLogo: chooseLogoImage,
        });


        fetch(`${API_URL}organisations${this.state.id}`, {
          method: this.state.id ? "put" : "post",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: strings
        })
          .then(res => {
            if (res.status == 200) {
              toast.success("Record saved successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });

              this.setState({ loader: "", button: false });
            }
            return res.json();
          })
          .then(res => {
            if (res.message) {
              toast.error(res.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
            }
            this.setState({ loader: "", button: false });
          })
          .catch(err => {
            console.warn(err);
            this.setState({ loader: "", button: false });
          });
      }
    };

    const convertBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (errors) => {
          reject(errors);
        };
      });
    };

    return (
      <div>
        <Container
          fluid
          className="main-content-container px-4"
          style={{ marginBottom: "200px" }}
        >
          <Row noGutters className="page-header py-4">
            <Col>
              <PageTitle
                title="Manage Organisations"
                subtitle={strings.overview}
                md="12"
                className="ml-sm-auto mr-sm-auto"
              />
            </Col>
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <Link to={"/organisations"} className="float-left">
                <Button
                  size="sm"
                  id="go_back"
                  theme="light"
                  className="mb-2 mr-1"
                >
                  Go Back
                </Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12">
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <Row>
                    <Col>
                      <h6 className="m-0">
                        {this.state.id
                          ? "Edit Organisations"
                          : "Create Organisations"}
                      </h6>
                    </Col>
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "flex-end"
                      }}
                    >
                      <FormCheckbox
                        onChange={() => {
                          this.setState({ status: !this.state.status });
                        }}
                        checked={status}
                        toggle
                      >
                        Status
                      </FormCheckbox>
                    </Col>
                  </Row>
                </CardHeader>
                <ListGroup flush>
                  <ListGroupItem className="p-3">
                    <Form id="ProfileForm">
                      <Row>
                        <Col>
                          <label htmlFor="code">{strings.org_code}</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">code</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="code"
                              value={org_code}
                              placeholder={strings.org_code}
                              onChange={e =>
                                this.setState({ org_code: e.target.value })
                              }
                              onBlur={() => {
                                let value = updateNumber(org_code);
                                this.setState({
                                  org_code: value
                                });
                              }}
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="rutNumber">RUT Number</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">code</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="rutNumber"
                              value={rutNumber}
                              placeholder="RUT Number"
                              onChange={e =>
                                this.setState({ rutNumber: e.target.value })
                              }
                              onBlur={() => {
                                let value = updateNumber(rutNumber);
                                this.setState({
                                  rutNumber: value
                                });
                              }}
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="name">Name</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">apartment</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="name"
                              placeholder="Name"
                              value={name}
                              onChange={e =>
                                this.setState({ name: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="rutReceptorSII">
                            RUT Receptor SII
                          </label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">location_city</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="rutReceptorSII"
                              value={rutReceptorSII}
                              placeholder="RUT Receptor SII"
                              onChange={e =>
                                this.setState({
                                  rutReceptorSII: e.target.value
                                })
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label htmlFor="boletaFchResol">
                            Boleta Fch Resol
                          </label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">location_city</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <DateRangePicker
                              // ref={this.refs.boletaFchResolRef}
                              ref={component => {
                                this.boletaFchResolRef = component;
                              }}
                              initialSettings={{
                                startDate: boletaFchResol,
                                singleDatePicker: true,
                                timePicker: true,
                                locale: {
                                  format: "Y-MM-DD hh:mm:ss A"
                                }
                              }}
                              onCallback={start => {
                                let value = this.convertDate(start._d);
                                this.setState({
                                  boletaFchResol: value
                                });
                              }}
                            >
                              <input type="text" className="form-control" />
                            </DateRangePicker>
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="boletaNroResol">
                            Boleta Nro Resol
                          </label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">location_city</i>
                              </InputGroupText>
                            </InputGroupAddon>

                            <FormInput
                              type="text"
                              id="boletaNroResol"
                              value={boletaNroResol}
                              placeholder="Boleta Nro Resol"
                              onChange={e =>
                                this.setState({
                                  boletaNroResol: e.target.value
                                })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="fchResol">Fch Resol</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">location_city</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <DateRangePicker
                              ref={component => (this.fchResolRef = component)}
                              initialSettings={{
                                startDate: fchResol,
                                singleDatePicker: true,
                                timePicker: true,
                                locale: {
                                  format: "Y-MM-DD hh:mm:ss A"
                                }
                              }}
                              onCallback={start => {
                                let value = this.convertDate(start._d);
                                this.setState({
                                  fchResol: value
                                });
                              }}
                            >
                              <input type="text" className="form-control" />
                            </DateRangePicker>

                            {/* <FormInput
                              type="text"
                              id="fchResol"
                              value={fchResol}
                              placeholder="Fch Resol"
                              onChange={e =>
                                this.setState({
                                  fchResol: e.target.value
                                })
                              }
                            /> */}
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="nroResol">Nro Resol</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">location_city</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="nroResol"
                              value={nroResol}
                              placeholder="Nro Resol"
                              onChange={e =>
                                this.setState({
                                  nroResol: e.target.value
                                })
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label htmlFor="city">City</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">location_city</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="city"
                              value={city}
                              placeholder="City"
                              onChange={e =>
                                this.setState({ city: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="state">State</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">
                                  emoji_transportation
                                </i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="state"
                              value={state}
                              placeholder="State"
                              onChange={e =>
                                this.setState({ state: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="rutEnvia">RUT Envia</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">location_city</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="rutEnvia"
                              value={rutEnvia}
                              placeholder="RUT Envia"
                              onChange={e =>
                                this.setState({
                                  rutEnvia: e.target.value
                                })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="indServicio">Ind Servicio</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">location_city</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="indServicio"
                              value={indServicio}
                              placeholder="Ind Servicio"
                              onChange={e =>
                                this.setState({ indServicio: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label htmlFor="billSequenceNumber">
                            Bill Sequence
                          </label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">location_city</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="number"
                              id="billSequenceNumber"
                              value={billSequenceNumber}
                              placeholder="Bill Sequence Number"
                              onChange={e =>
                                this.setState({
                                  billSequenceNumber: e.target.value
                                })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="invoiceSequenceNumber">
                            Invoice Sequence
                          </label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">location_city</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="number"
                              id="invoiceSequenceNumber"
                              value={invoiceSequenceNumber}
                              placeholder="Invoice Sequence Number"
                              onChange={e =>
                                this.setState({
                                  invoiceSequenceNumber: e.target.value
                                })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="creditNoteSequenceNumber">
                            CreditNote Sequence
                          </label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">location_city</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="number"
                              id="creditNoteSequenceNumber"
                              value={creditNoteSequenceNumber}
                              placeholder="CreditNote Sequence Number"
                              onChange={e =>
                                this.setState({
                                  creditNoteSequenceNumber: e.target.value
                                })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="debitNoteSequenceNumber">
                            DebitNote Sequence
                          </label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">location_city</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="number"
                              id="debitNoteSequenceNumber"
                              value={debitNoteSequenceNumber}
                              placeholder="DebitNote Sequence Number"
                              onChange={e =>
                                this.setState({
                                  debitNoteSequenceNumber: e.target.value
                                })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="deliveryNoteSequenceNumber">
                            DeliveryNote Sequence
                          </label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">location_city</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="number"
                              id="deliveryNoteSequenceNumber"
                              value={deliveryNoteSequenceNumber}
                              placeholder="DeliveryNote Sequence Number"
                              onChange={e =>
                                this.setState({
                                  deliveryNoteSequenceNumber: e.target.value
                                })
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      {/* code started */}
                      <Row>
                        <Col>
                          <label htmlFor="billWithoutTaxSequenceNumber">
                            Bill Without Tax Sequence
                          </label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">location_city</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="number"
                              id="billWithoutTaxSequenceNumber"
                              value={billWithoutTaxSequenceNumber}
                              placeholder="Bill WithoutTax Sequence Number"
                              onChange={e =>
                                this.setState({
                                  billWithoutTaxSequenceNumber: e.target.value
                                })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="invoiceWithoutTaxSequenceNumber">
                            Invoice Without Tax Sequence
                          </label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">location_city</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="number"
                              id="invoiceWithoutTaxSequenceNumber"
                              value={invoiceWithoutTaxSequenceNumber}
                              placeholder="Invoice WithoutTax Sequence Number"
                              onChange={e =>
                                this.setState({
                                  invoiceWithoutTaxSequenceNumber: e.target.value
                                })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="quoteSequenceNumber">
                            Quote Sequence
                          </label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">location_city</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="number"
                              id="quoteSequenceNumber"
                              value={quoteSequenceNumber}
                              placeholder="Quote Sequence Number"
                              onChange={e =>
                                this.setState({
                                  quoteSequenceNumber: e.target.value
                                })
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label htmlFor="dirOrigen">Dir Origen</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">home</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              id="dirOrigen"
                              value={dirOrigen}
                              placeholder="Dir Origen"
                              onChange={e =>
                                this.setState({ dirOrigen: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="cmnaOrigen">Cmna Origen</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">home</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              id="cmnaOrigen"
                              value={cmnaOrigen}
                              placeholder="Cmna Origen"
                              onChange={e =>
                                this.setState({ cmnaOrigen: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label htmlFor="address">Address</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">home</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormTextarea
                              id="address"
                              value={address}
                              placeholder="Address"
                              onChange={e =>
                                this.setState({ address: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="siiAddress">SII Address</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">business</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormTextarea
                              id="siiAddress"
                              value={siiAddress}
                              placeholder="SII Address"
                              onChange={e =>
                                this.setState({ siiAddress: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label htmlFor="giro">Giro</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">
                                  business_center
                                </i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormTextarea
                              id="giro"
                              value={giro}
                              placeholder="Giro"
                              onChange={e =>
                                this.setState({ giro: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="giroCodes">Giro Codes</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">
                                  business_center
                                </i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormTextarea
                              id="giroCodes"
                              value={giroCodes}
                              placeholder="Giro Codes"
                              onChange={e =>
                                this.setState({ giroCodes: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Col
                        style={{
                          marginLeft: '5px',
                          marginBottom: '10px',
                        }}
                      >
                        <Row>
                          <label for="companylog">Logo</label>
                          {this.state.logoErrorMessage && this.state.logoErrorMessage !== '' && <p style={{ marginLeft: '20px', height: '0px', color: 'red' }}>{this.state.logoErrorMessage}</p>}
                        </Row>
                        <Row>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            {this.state.chooseLogoImage && this.state.chooseLogoImage !== ''
                              &&
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginRight: '10px',
                                }}
                              >
                                <img
                                  id="main-logo"
                                  className="align-top"
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                  }}
                                  onClick={() => {
                                    var image = new Image();
                                    image.src = this.state.chooseLogoImage;
                                    var w = window.open("");
                                    w.document.write(image.outerHTML);
                                  }}
                                  src={this.state.chooseLogoImage}
                                  alt="Company Logo"
                                />
                                <Button
                                  outline
                                  theme="light"
                                  onClick={() => this.setState({ chooseLogoImage: '' })}
                                  // disabled={this.state.button}
                                  className="ml-3"
                                >
                                  <i className="material-icons" style={{ fontSize: 25, color: 'red' }}>
                                    delete_outline
                                  </i>
                                </Button>
                              </div>
                              // </a> 
                            }
                            <input
                              accept=".png, .jpg, .jpeg"
                              type="file"
                              id="company-logo"
                              onChange={async (e) => {
                                if (e.target.files.length > 0) {
                                  this.setState({ logoErrorMessage: '' })
                                  const fileObject = e.target.files[0];
                                  console.log("🚀 ~ file: ManageOrg.js ~ line 1016 ~ EditUserProfile ~ onChange={async ~ fileObject", fileObject)
                                  const fileSize = e.target.files[0].size
                                  const file = Math.round(fileSize / 1024)
                                  if (file < 10) {
                                    const data = await convertBase64(e.target.files[0])
                                    console.log('data:', data)
                                    if (data !== null && data !== undefined) {
                                      this.setState({ chooseLogoImage: data })
                                    }
                                  } else {
                                    this.setState({ logoErrorMessage: '*Please upload an image less than 10 kb size' })
                                    this.setState({ chooseLogoImage: '' })
                                  }
                                } else {
                                  console.log('test')
                                  this.setState({ chooseLogoImage: '' })
                                }
                              }}
                            />
                          </div>
                        </Row>
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "flex-start"
                        }}
                      >
                        <FormCheckbox
                          onChange={() => {
                            this.setState({
                              submit_bill_sii: !this.state.submit_bill_sii
                            });
                          }}
                          checked={submit_bill_sii}
                          toggle
                        >
                          Submit Bill to the SII
                        </FormCheckbox>

                        <FormCheckbox
                          className="ml-4"
                          onChange={() => {
                            this.setState({
                              submit_invoice_sii: !this.state.submit_invoice_sii
                            });
                          }}
                          checked={submit_invoice_sii}
                          toggle
                        >
                          Submit Invoice to the SII
                        </FormCheckbox>

                        <FormCheckbox
                          className="ml-4"
                          onChange={() => {
                            this.setState({
                              submit_dispatchnote_sii: !this.state.submit_dispatchnote_sii
                            });
                          }}
                          checked={submit_dispatchnote_sii}
                          toggle
                        >
                          Submit Dispatch Note to the SII
                        </FormCheckbox>

                        <FormCheckbox
                          className="ml-4"
                          onChange={() => {
                            this.setState({
                              restaurantModule: !this.state.restaurantModule
                            });
                          }}
                          checked={restaurantModule}
                          toggle
                        >
                          Restaurante
                        </FormCheckbox>

                        <ToastContainer />
                        <Button
                          theme="accent"
                          onClick={() => handleClick()}
                          disabled={this.state.button}
                          className="ml-auto"
                        >
                          <i
                            className={this.state.loader}
                            style={{ fontSize: "15px" }}
                          ></i>{" "}
                          Save
                        </Button>
                      </Col>
                    </Form>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            </Col>
            <Col>
              <Link to={"/organisations"} className="float-left">
                <Button
                  size="sm"
                  id="go_back"
                  theme="light"
                  className="mb-2 mr-1"
                  style={{
                    marginTop: "-84px",
                    position: "relative",
                    left: "-100px"
                  }}
                >
                  Go Back
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
